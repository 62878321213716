import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type WidgetModalData = {
    funcId: string;
    funcName: string;
    funcLabel: string;
    path: string;
    sectorId: string;
    isValid: boolean;
    isEdit: boolean;
}

export const initialWidgetModalData: WidgetModalData = {
    funcId: "",
    funcName: "",
    funcLabel: "",
    path: "",
    sectorId: "",
    isValid: false,
    isEdit: false
}

type InitialWidgetState = {
    showWidgetModal: boolean;
    widgetModalData: WidgetModalData
}

const initialState: InitialWidgetState = {
    showWidgetModal: false,
    widgetModalData: initialWidgetModalData
}

export const widgetSlice = createSlice({
    name: 'widget',
    initialState,
    reducers: {
        setShowWidgetModal: (state, action: PayloadAction<boolean>) => {
            state.showWidgetModal = action.payload
        },
        setWidgetModalData: (state, action: PayloadAction<WidgetModalData>) => {
            state.widgetModalData = action.payload
        },
    },
})

export const { setShowWidgetModal, setWidgetModalData } = widgetSlice.actions

export default widgetSlice.reducer

