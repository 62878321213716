import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Map, View } from 'ol'
import { CENTER } from '../constants/map';
import { MAX_ZOOM } from '../constants/map';
import { LayerListItem } from '../types/layer-list-item';
import { AttributeInfo, LayerInfo, } from '../types/layer';

const initialMap = new Map({
  view: new View({
    center: CENTER,
    zoom: 5,
    maxZoom: MAX_ZOOM,
  }),
});
const initalLayerState = {
  layerList: [],
  unpublishedLayers: [],
  activeLayer: null,
  mapName: null,
  datasetName: "",
  attributeList: []
};
const initialControlState = {
  showLayerList: false,
}
const initialConfig = {
  showMap: false,
  selectedRoleId: '',
  selectedRoleName: "Select a role"
}

export const initialCurrentData: LayerInfo = {
  id: "",
  client_id: "",
  is_published: false,
  layer_name: "",
  sectors: [],
  map_name: "",
  url: "",
}

type InitialDataManagState = {
  map: Map;
  config: {
    showMap: boolean;
    selectedRoleId: string;
    selectedRoleName: string;
  };
  layer: {
    layerList: Array<LayerListItem>;
    activeLayer: LayerListItem | null;
    mapName: string | null;
    attributeList: Array<AttributeInfo>;
    datasetName: string | null;
  };
  control: {
    showLayerList: boolean;
  };
  showUploadDatasetModal: boolean;
  showSectorAssignModal: boolean;
  showLayerConfigModal: boolean;
  showAssociatedClientsModal: boolean;
  currentDataset: LayerInfo;
};
const initialState: InitialDataManagState = {
  map: initialMap,
  config: initialConfig,
  layer: initalLayerState,
  control: initialControlState,
  showUploadDatasetModal: false,
  showSectorAssignModal: false,
  showLayerConfigModal: false,
  showAssociatedClientsModal: false,
  currentDataset: initialCurrentData
}

export const dataManagSlice = createSlice({
  name: "dataManagement",
  initialState,
  reducers: {
    setMap: (state, action: PayloadAction<Map>) => {
      state.map = action.payload;
    },
    processLayerList: (state, action: PayloadAction<Array<LayerListItem>>) => {
      const layerList = action.payload.map((layer) => {
        return {
          id: layer.wmslayer,
          layerName: layer.layer_name,
          mapName: "",
          wmsLayerName: layer.wmslayer,
          wfsLayerUrl: layer.url,
          layertitle: layer.layertitle,
          isChecked: layer.selected,
        };
      });
      state.layer.activeLayer = layerList.filter((layer) => layer.isChecked)[0];
      state.layer.layerList = layerList;
    },
    setLayerList: (state, action: PayloadAction<Array<LayerListItem>>) => {
      state.layer.layerList = action.payload;
    },
    setActiveLayer: (state, action: PayloadAction<LayerListItem>) => {
      state.layer.activeLayer = action.payload;
    },
    setShowLayerList: (state, action: PayloadAction<boolean>) => {
      state.control.showLayerList = action.payload;
    },
    setShowMap: (state, action: PayloadAction<boolean>) => {
      state.config.showMap = action.payload;
    },
    setRoleId: (state, action: PayloadAction<string>) => {
      state.config.selectedRoleId = action.payload;
    },
    setRoleName: (state, action: PayloadAction<string>) => {
      state.config.selectedRoleName = action.payload;
    },
    setMapName: (state, action: PayloadAction<string>) => {
      state.layer.mapName = action.payload;
    },
    resetConfig: (state) => {
      state.config = initialConfig;
    },
    setShowUploadDatasetModal: (state, action: PayloadAction<boolean>) => {
      state.showUploadDatasetModal = action.payload;
    },
    setShowSectorAssignModal: (state, action: PayloadAction<boolean>) => {
      state.showSectorAssignModal = action.payload;
    },
    setShowLayerConfigModal: (state, action: PayloadAction<boolean>) => {
      state.showLayerConfigModal = action.payload;
    },
    setShowAssociatedClientsModal: (state, action: PayloadAction<boolean>) => {
      state.showAssociatedClientsModal = action.payload;
    },
    setDatasetName: (state, action: PayloadAction<string>) => {
      state.layer.datasetName = action.payload;
    },
    setCurrentDataset: (state, action: PayloadAction<LayerInfo>) => {
      state.currentDataset = action.payload;
    },
    setAttributesList: (state, action: PayloadAction<Array<AttributeInfo>>) => {
      state.layer.attributeList = action.payload;
    },
    updateAttributeVisibility: (
      state,
      action: PayloadAction<{ name: string; checked: boolean }>
    ) => {
      const { name, checked } = action.payload;
      state.layer.attributeList.find(
        (attribute) => attribute.column_name === name
      )!.is_visible = checked;
    },
    updateAttributeEditable: (
      state,
      action: PayloadAction<{ name: string; checked: boolean }>
    ) => {
      const { name, checked } = action.payload;
      state.layer.attributeList.find(
        (attribute) => attribute.column_name === name
      )!.is_editable = checked;
    },
    updateAttributeVisibleAll: (state, action: PayloadAction<boolean>) => {
      const checked = action.payload;
      state.layer.attributeList.map(
        (attribute) => { if(attribute.column_name !== "objectid") 
        attribute.is_visible = checked 
      }
      );
    },
    updateAttributeEditableAll: (state, action: PayloadAction<boolean>) => {
      const checked = action.payload;
      state.layer.attributeList.map(
        (attribute) => {if(attribute.column_name !== "objectid")  attribute.is_editable = checked }
      );
    },
  },
});
export const {
  setMap,
  processLayerList,
  setLayerList,
  setActiveLayer,
  setShowLayerList,
  setShowMap,
  setRoleId,
  setRoleName,
  setMapName,
  resetConfig,
  setShowUploadDatasetModal,
  setShowSectorAssignModal,
  setShowLayerConfigModal,
  setShowAssociatedClientsModal,
  setCurrentDataset,
  setDatasetName,
  setAttributesList,
  updateAttributeVisibility,
  updateAttributeEditable,
  updateAttributeVisibleAll,
  updateAttributeEditableAll,
} = dataManagSlice.actions;

export default dataManagSlice.reducer