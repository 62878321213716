import { WidgetInstanceData } from 'types/widget-instance'
import { LayerListItem } from '../types/layer-list-item'

const prepareLayerList = (layerConfiguration: WidgetInstanceData): Array<LayerListItem> => {
    let layerList: Array<LayerListItem> = []
    const { mapLayers, mapLayerRuleset } = layerConfiguration
    mapLayers?.forEach((ml) => {
        const { id, map_name, Layers } = ml
        const mapLyrRuleset = mapLayerRuleset.find((item) => item.id === id)
        if (mapLyrRuleset) {
            const { LayerRule } = mapLyrRuleset
            Layers?.forEach((l) => {
                const lyrListItem: LayerListItem = {
                    id: l.id.toString(),
                    layerName: l.layer_name,
                    mapName: map_name,
                    wmsLayerName: `${map_name.split(":")[0]}:${l.layer_name}`,
                    wfsLayerUrl: l.url,
                    isChecked: false,
                    layertitle: l.layertitle,
                    jobActive: l.job_active,
                    jobRun: l.job_run
                }
                const lyrRule = LayerRule?.find((item) => item.id === l.id)
                if (lyrRule) {
                    const { ruleset_name } = lyrRule
                    layerList.push({
                        ...lyrListItem,
                        ruleSetName: ruleset_name,
                    })
                } else {
                    layerList.push(lyrListItem)
                }
            })
        } else {
            Layers?.forEach((l) => {
                layerList.push({
                    id: l.id.toString(),
                    layerName: l.layer_name,
                    mapName: map_name,
                    wmsLayerName: `${map_name.split(":")[0]}:${l.layer_name}`,
                    wfsLayerUrl: l.url,
                    isChecked: false,
                    jobActive: l.job_active,
                    jobRun: l.job_run
                })
            })
        }
    })
    let rulesetItems: Array<LayerListItem> = []
    let nonRulesetItems: Array<LayerListItem> = []
    layerList.forEach(item => {
        if (item.ruleSetName) {
            rulesetItems.push(item)
        } else {
            nonRulesetItems.push(item)
        }
    })
    return [...rulesetItems, ...nonRulesetItems]
}

export default prepareLayerList