import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type SectorModalData = {
    sector_code: string,
    sector_name: string,
    sector_id: string,
    isValid: boolean;
    isEdit: boolean;
}

export const initialSectorModalData: SectorModalData = {
    sector_code: "",
    sector_name: "",
    sector_id: "",
    isValid: false,
    isEdit: false
}

type InitialSectorState = {
    showSectorModal: boolean;
    sectorModalData: SectorModalData
}

const initialState: InitialSectorState = {
    showSectorModal: false,
    sectorModalData: initialSectorModalData
}

export const sectorSlice = createSlice({
    name: 'sector',
    initialState,
    reducers: {
        setShowSectorModal: (state, action: PayloadAction<boolean>) => {
            state.showSectorModal = action.payload
        },
        setSectorModalData: (state, action: PayloadAction<SectorModalData>) => {
            state.sectorModalData = action.payload
        },
    },
})

export const { setShowSectorModal, setSectorModalData } = sectorSlice.actions

export default sectorSlice.reducer

