import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import appRole from 'helpers/appRole';
import { User, UserInfo } from 'types/user';

const token = localStorage.getItem('token') ? localStorage.getItem('token') : null


type InitialAuthState = {
    token: string | null;
    userInfo: UserInfo | null;
}

const initialState: InitialAuthState = {
    token,
    userInfo: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUserInfo: (state, action: PayloadAction<User>) => {
            state.userInfo = { ...action.payload, role: appRole(action.payload.role_id) }
        },
    },
})

export const { setUserInfo } = authSlice.actions

export default authSlice.reducer

