import { combineReducers, configureStore, AnyAction, Reducer } from '@reduxjs/toolkit'
import authReducer from "../state/authSlice"
import insightReducer from "../state/insightSlice"
import widgetDashboardReducer from "../state/widgetDashboardSlice"
import primaryValidationReducer from "../state/primaryValidationSlice"
import bulkValidationReducer from "../state/bulkValidationSlice"
import bulkComparativeValidatorReducer from "../state/bulkComparativeValidatorSlice"
import bulkValidInspectionReducer from "../state/bulkValidInspectionSlice"
import layoutReducer from "../state/layoutSlice"
import clientReducer from "../state/clientSlice"
import adminReducer from "../state/adminSlice"
import widgetReducer from "../state/widgetSlice"
import roleReducer from "../state/roleSlice"
import dbUserReducer from "../state/dbUserSlice"
import widgetInstanceReducer from "../state/widgetInstanceSlice"
import sectorReducer from "../state/sectorSlice"
import teamReducer from "../state/teamSlice"
import boldBIReducer from "../state/boldBISlice"
import analyticReducer from "../state/analyticSlice"
import dataManagReducer from "../state/dataManagSlice"
import easementReducer from "../state/easementSlice"
import loaderReducer from "../state/loaderSlice" 

const combinedReducer = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  insight: insightReducer,
  widgetDashboard: widgetDashboardReducer,
  primaryValidation: primaryValidationReducer,
  bulkValidation: bulkValidationReducer,
  bulkComparativeValidator: bulkComparativeValidatorReducer,
  bulkVaildInspection: bulkValidInspectionReducer,
  dataManagement: dataManagReducer,
  client: clientReducer,
  admin: adminReducer,
  widget: widgetReducer,
  role: roleReducer,
  dbUser: dbUserReducer,
  widgetInstance: widgetInstanceReducer,
  team: teamReducer,
  sector: sectorReducer,
  boldBI: boldBIReducer,
  analytic: analyticReducer,
  easement: easementReducer,
  loader: loaderReducer,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    if (action.type === 'logout') {
        state = {} as RootState;
    }
    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})

export type RootState = ReturnType<typeof combinedReducer>
export type AppDispatch = typeof store.dispatch