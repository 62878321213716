import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Map, View } from 'ol'
import { CENTER, MAX_ZOOM, TOOLBAR_ITEMS } from '../constants/map'
import { LayerListItem } from '../types/layer-list-item'
import { SingleSelectionToolbarItem, ToolbarItem } from '../types/toolbar-item'
import { AttributeInfoData } from '../types/attribute-info'
import { Geometry } from 'ol/geom'
import { AttributeQueryParam } from '../types/attribute-filter'
import prepareLayerList from '../helpers/prepareLayerList'
import VectorSource from 'ol/source/Vector'
import { vector } from '../components/olReact/source'
import { WidgetInstanceData } from 'types/widget-instance'

const initialMap = new Map({
    view: new View({
        center: CENTER,
        zoom: 5,
        maxZoom: MAX_ZOOM,
    }),
});

const initalLayerState = {
    layerList: [],
    activeLayer: null
}

const initialToolbarItems = TOOLBAR_ITEMS.map((item) => ({ ...item, ...(item.selection === "multi" && { isActive: false }) })) as Array<ToolbarItem>

const initialControlState = {
    showLayerList: false,
    showToolbar: false,
    showTileBar: false,
    toolbarItems: initialToolbarItems,
    selectedDrawGeometryTool: null
}

type InitialPrimaryValidationState = {
    map: Map;
    layer: {
        layerList: Array<LayerListItem>;
        activeLayer: LayerListItem | null
    };
    control: {
        showLayerList: boolean;
        showToolbar: boolean;
        showTileBar: boolean;
        toolbarItems: Array<ToolbarItem>
        selectedDrawGeometryTool: SingleSelectionToolbarItem | null
    };
    showAttributeInfoModal: boolean;
    attributeInfoData: AttributeInfoData | null;
    selectedFeatureId: string;
    drawSource: VectorSource<Geometry>;
    drawGeometry: Geometry | null;
    attributeQueryParams: Array<AttributeQueryParam>;
    extentGeometry: string;
    selectedFilter: string | null;
}

const initialState: InitialPrimaryValidationState = {
    map: initialMap,
    layer: initalLayerState,
    control: initialControlState,
    showAttributeInfoModal: false,
    attributeInfoData: null,
    selectedFeatureId: "",
    drawSource: vector({ wrapX: false }),
    drawGeometry: null,
    attributeQueryParams: [],
    extentGeometry: "",
    selectedFilter: null,
}

export const primaryValidationSlice = createSlice({
    name: "primaryValidation",
    initialState,
    reducers: {
        resetPrimaryValidationInstance: () => initialState,
        setMap: (state, action: PayloadAction<Map>) => {
            state.map = action.payload
        },
        processLayerConfiguration: (state, action: PayloadAction<WidgetInstanceData>) => {
            const layerList = prepareLayerList(action.payload)
            state.layer.layerList = [...layerList]
            if (state.layer.layerList.length) {
                state.layer.layerList[0].isChecked = true
                state.layer.activeLayer = state.layer.layerList[0]
            }
        },
        setLayerList: (state, action: PayloadAction<Array<LayerListItem>>) => {
            state.layer.layerList = action.payload
            const layerListItem = state.layer.layerList.find(x => x.id === state.layer.activeLayer?.id)
            if (layerListItem) {
                state.layer.activeLayer = layerListItem
            }
        },
        setActiveLayer: (state, action: PayloadAction<LayerListItem>) => {
            state.layer.activeLayer = action.payload
            state.selectedFeatureId = ""
        },
        setShowLayerList: (state, action: PayloadAction<boolean>) => {
            state.control.showLayerList = action.payload
        },
        setShowToolbar: (state, action: PayloadAction<boolean>) => {
            state.control.showToolbar = action.payload
        },
        setShowTilebar: (state, action: PayloadAction<boolean>) => {
            state.control.showTileBar = action.payload
        },
        toggleToolbarItem: (state, action: PayloadAction<string>) => {
            const toolbarItems = state.control.toolbarItems
            const item = toolbarItems.find(x => x.name === action.payload)
            if (item && item.selection === "multi") {
                item.isActive = !item.isActive
            }
        },
        setToolbarItemActiveStatus: (state, action: PayloadAction<{ name: string, isActive: boolean }>) => {
            const toolbarItems = state.control.toolbarItems
            const item = toolbarItems.find(x => x.name === action.payload.name)
            if (item && item.selection === "multi")
                item.isActive = action.payload.isActive
        },
        setShowAttributeInfoModal: (state, action: PayloadAction<boolean>) => {
            state.showAttributeInfoModal = action.payload
        },
        setAttributeInfoData: (state, action: PayloadAction<AttributeInfoData>) => {
            state.attributeInfoData = action.payload
        },
        setSelectedFeatureId: (state, action: PayloadAction<string>) => {
            state.selectedFeatureId = action.payload
        },
        setSelectedDrawGeometryTool: (state, action: PayloadAction<SingleSelectionToolbarItem | null>) => {
            if (action.payload && state.control.selectedDrawGeometryTool?.name === action.payload.name) {
                state.control.selectedDrawGeometryTool = null
            } else {
                state.control.selectedDrawGeometryTool = action.payload
            }
        },
        setDrawGeometry: (state, action: PayloadAction<Geometry | null>) => {
            state.drawGeometry = action.payload
        },
        setAttributeQueryParams: (state, action: PayloadAction<Array<AttributeQueryParam>>) => {
            state.attributeQueryParams = action.payload
        },
        setExtentGeometry: (state, action: PayloadAction<string>) => {
            state.extentGeometry = action.payload
        },
        setSelectedFilter: (state, action: PayloadAction<string | null>) => {
            state.selectedFilter = action.payload
        },
    }
})

export const {
    setMap, setActiveLayer, setLayerList, setShowLayerList, setShowToolbar, setShowTilebar, setToolbarItemActiveStatus,
    toggleToolbarItem, processLayerConfiguration, resetPrimaryValidationInstance, setShowAttributeInfoModal, setAttributeInfoData,
    setSelectedFeatureId, setSelectedDrawGeometryTool, setDrawGeometry, setAttributeQueryParams, setExtentGeometry, setSelectedFilter
} = primaryValidationSlice.actions

export default primaryValidationSlice.reducer