import { ADMIN_INDEX_ROUTE, DASHBOARD_USER_INDEX_ROUTE, SUPER_ADMIN_INDEX_ROUTE } from "../constants/route"
import { SUPER_ADMIN, ADMIN, USER } from "../constants/role"

const getPrivateIndexRoute = (role: string) => {
    switch (role) {
        case SUPER_ADMIN:
            return SUPER_ADMIN_INDEX_ROUTE
        case ADMIN:
            return ADMIN_INDEX_ROUTE
        case USER:
            return DASHBOARD_USER_INDEX_ROUTE
        default:
            return ""
    }
}

export default getPrivateIndexRoute