const config = {
  BASE_API_URL: `${process.env.REACT_APP_BASE_URL}/api/`,
  CORS_URL: `${process.env.REACT_APP_BASE_URL}/api/request/`,
  SPATIAL_URL: process.env.REACT_APP_SPATIAL_URL,
  GEOSERVER_REST: `${process.env.REACT_APP_GEOSERVER}rest/workspaces/`,
  GEOSERVER_VALIDATION_FLAG: process.env.REACT_APP_GEOSERVER_VALIDATION_FLAG,
  GEOSERVER: process.env.REACT_APP_GEOSERVER ?? "",
  ML_PREDICTION: `${process.env.REACT_APP_BASE_URL}/`,
  GEOSERVER_WFS: `${process.env.REACT_APP_GEOSERVER}wfs`,
  WMS_GEOSERVER: `${process.env.REACT_APP_GEOSERVER}wms?`,
  GEOSERVER_LEGEND: `${process.env.REACT_APP_GEOSERVER}wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=`,
  SERVER_URL_GEOSERVER: `${process.env.REACT_APP_GEOSERVER}wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=`,
  WMS_Getcapabilities: `${process.env.REACT_APP_GEOSERVER}wms?service=WMS&version=1.1.0&request=GetCapabilities`,
  AG_GRID_LICENSE: process.env.REACT_APP_AG_GRID_LICENSE ?? "",
  ENV: process.env.REACT_APP_ENV ?? "",
  BOLD_BI_SERVER_URL: process.env.REACT_APP_BOLD_BI_SERVER_URL,
  BOLD_BI_API_URL: `${process.env.REACT_APP_BOLD_BI_SERVER_URL}bi/api/`
};

export default config;
