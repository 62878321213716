import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type InitialLoadingState = {
  isLoading: boolean;
};

const initialState: InitialLoadingState = {
  isLoading: false,
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setloader: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    }
  },
});
export const { setloader } = loaderSlice.actions;

export default loaderSlice.reducer;