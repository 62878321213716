import App from "./App";
import "./assets/scss/_main.scss";
import config from "./config";
import { createRoot } from 'react-dom/client';
import "@fortawesome/fontawesome-free/css/all.min.css";

import { LicenseManager } from 'ag-grid-enterprise'
import { StrictMode } from "react";
LicenseManager.setLicenseKey(config.AG_GRID_LICENSE)

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
