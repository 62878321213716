import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ClientsInSectorWithPermission } from 'types/sector';
import { Admin } from 'types/user';

type AdminModalData = {
    isValid: boolean;
    isEdit: boolean;
}

export const initialAdminModalData: AdminModalData = {
    isValid: false,
    isEdit: false
}

export const initialCurrentAdmin: Admin = {
    id: "",
    clientid: "",
    client_name: "",
    account_confirmation: false,
    email: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    role_id: "",
    user_name: "",
    is_boldbi_user: true
}

type InitialAdminState = {
    showAdminModal: boolean;
    adminModalData: AdminModalData;
    currentAdmin: Admin;
    showPermissionModal: boolean;
    clientsInSectorsWithPermissions: Array<ClientsInSectorWithPermission>
}

const initialState: InitialAdminState = {
    showAdminModal: false,
    adminModalData: initialAdminModalData,
    currentAdmin: initialCurrentAdmin,
    showPermissionModal: false,
    clientsInSectorsWithPermissions: []
}

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setShowAdminModal: (state, action: PayloadAction<boolean>) => {
            state.showAdminModal = action.payload
        },
        setAdminModalData: (state, action: PayloadAction<AdminModalData>) => {
            state.adminModalData = action.payload
        },
        setCurrentAdmin: (state, action: PayloadAction<Admin>) => {
            state.currentAdmin = action.payload
        },
        setShowPermissionModal: (state, action: PayloadAction<boolean>) => {
            state.showPermissionModal = action.payload
        },
        setClientsInSectorsWithPermissions: (state, action: PayloadAction<Array<ClientsInSectorWithPermission>>) => {
            state.clientsInSectorsWithPermissions = action.payload
        },
        updateClientsInSectorsWithPermissions: (state, action: PayloadAction<{ name: "is_assign" | "is_delete", id: string, checked: boolean }>) => {
            const { name, checked, id } = action.payload
            state.clientsInSectorsWithPermissions.find(item => item.id === id)![name] = checked
        },
    },
})

export const { setShowAdminModal, setAdminModalData, setCurrentAdmin, setShowPermissionModal,
    setClientsInSectorsWithPermissions, updateClientsInSectorsWithPermissions
} = adminSlice.actions

export default adminSlice.reducer

