import { ToastContainer, Slide, Zoom, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Snackbar = () => {
  return (
    <ToastContainer
      style={{ zIndex: 9999999 }}
      position="top-right"
      transition={Flip}
      autoClose={1000}
    />
  );
};

export default Snackbar;
