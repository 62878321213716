import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type InitialLayoutState = {
    isNavbar: boolean
    isWidgetsOpen:boolean
}

const initialState: InitialLayoutState = {
    isNavbar: true,
    isWidgetsOpen:true
}

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setNavbar: (state, action: PayloadAction<boolean>) => {
            state.isNavbar = action.payload
        },
        setWidgetExpand : (state, action: PayloadAction<boolean>) => {
            state.isWidgetsOpen = action.payload
        },
    },
})

export const { setNavbar, setWidgetExpand } = layoutSlice.actions

export default layoutSlice.reducer

