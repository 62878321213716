import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { SorFieldDataSet, SorFieldDataSetListItem } from '../types/bulk-comparative-validator';

type ConfigStep = "step1" | "step2"

type InitialBulkComparativeValidatorState = {
    currentConfigStep: ConfigStep;
    sorFieldDataSets: Array<SorFieldDataSet>;
    sorFieldDataSetList: Array<SorFieldDataSetListItem>;
}

const initialState: InitialBulkComparativeValidatorState = {
    currentConfigStep: "step1",
    sorFieldDataSets: [],
    sorFieldDataSetList: [],
}

export const bulkComparativeValidatorSlice = createSlice({
    name: "bulkComparativeValidator",
    initialState,
    reducers: {
        resetBulkComparativeValidatorInstance: () => initialState,
        setCurrentConfigStep: (state, action: PayloadAction<ConfigStep>) => {
            state.currentConfigStep = action.payload
        },
        setSorFieldDataSets: (state, action: PayloadAction<Array<SorFieldDataSet>>) => {
            if (action.payload) state.sorFieldDataSets = action.payload
        },
        addSorFieldDataSet: (state, action: PayloadAction<SorFieldDataSet>) => {
            state.sorFieldDataSets.push(action.payload)
        },
        deleteSorFieldDataSet: (state, action: PayloadAction<string>) => {
            state.sorFieldDataSets = state.sorFieldDataSets.filter((sorFieldDataSet) => sorFieldDataSet.id !== action.payload)
        },
        processSorFieldDataSetList: (state, action: PayloadAction<Array<SorFieldDataSet>>) => {
            const sorFieldDataSetList: Array<SorFieldDataSetListItem> = action.payload.map((sorFieldDataSet) => {
                return {
                    ...sorFieldDataSet,
                    isChecked: false
                }
            })
            state.sorFieldDataSetList = sorFieldDataSetList
        },
        setSorFieldDataSetList: (state, action: PayloadAction<Array<SorFieldDataSetListItem>>) => {
            state.sorFieldDataSetList = action.payload
        },
    }
})

export const {
    setCurrentConfigStep, setSorFieldDataSets, addSorFieldDataSet, deleteSorFieldDataSet, processSorFieldDataSetList,
    resetBulkComparativeValidatorInstance, setSorFieldDataSetList
} = bulkComparativeValidatorSlice.actions

export default bulkComparativeValidatorSlice.reducer