import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type AnalyticModalData = {
    analyticId: string;
    AnalyticsName: string;
    AnalyticsLable: string;
    EngineData: {
        id: string;
        rulesets: Array<{
            id: string
        }>
    };
    isValid: boolean;
    isEdit: boolean;
}

export const initialAnalyticModalData: AnalyticModalData = {
    analyticId: "",
    AnalyticsName: "",
    AnalyticsLable: "",
    EngineData: {
        id: "",
        rulesets: []
    },
    isValid: false,
    isEdit: false
}

type SectorAssignModalData = {
    analyticId: string;
    AnalyticsName: string;
    AnalyticsLable: string;
    sectorIds: Array<string>;
    isValid: boolean;
}

export const initialSectorAssignModalData: SectorAssignModalData = {
    analyticId: "",
    AnalyticsName: "",
    AnalyticsLable: "",
    sectorIds: [],
    isValid: false
}

type InitialAnalyticState = {
    showAnalyticModal: boolean;
    analyticModalData: AnalyticModalData;
    showSectorAssignModal: boolean;
    sectorAssignModalData: SectorAssignModalData
}

const initialState: InitialAnalyticState = {
    showAnalyticModal: false,
    analyticModalData: initialAnalyticModalData,
    showSectorAssignModal: false,
    sectorAssignModalData: initialSectorAssignModalData
}

export const analyticSlice = createSlice({
    name: 'analytic',
    initialState,
    reducers: {
        setShowAnalyticModal: (state, action: PayloadAction<boolean>) => {
            state.showAnalyticModal = action.payload
        },
        setAnalyticModalData: (state, action: PayloadAction<AnalyticModalData>) => {
            state.analyticModalData = action.payload
        },
        setShowSectorAssignModal: (state, action: PayloadAction<boolean>) => {
            state.showSectorAssignModal = action.payload
        },
        setSectorAssignModalData: (state, action: PayloadAction<SectorAssignModalData>) => {
            state.sectorAssignModalData = action.payload
        },
    },
})

export const { setShowAnalyticModal, setAnalyticModalData, setShowSectorAssignModal, setSectorAssignModalData } = analyticSlice.actions

export default analyticSlice.reducer

